<template>
  <div>
    <b-modal
      @hidden="toggleFacebookModal"
      @ok="toggleFacebookModal"
      v-model="internalModalVisible"
      no-close-on-backdrop
      hide-footer
      centered
      size="lg"
      id="Modal"
      :title="
        modalType == 'facebook'
          ? ' Select Meta Account'
          : 'Select Amazon Profile'
      "
    >
      <!-- Meta Account selector modal. -->
      <div v-if="modalType == 'facebook'">
        <input
          placeholder="search meta account."
          class="search-option"
          v-model="searchAccount"
        />
        <b-row style="margin-top: 5px; margin-bottom: 10px;">
          <b-col cols="12"> 
            <div v-if="isFacebookCustomersEmpty"></div>
            <!-- <div style="display: flex; justify-content:right" v-else>
              <button
                v-if="isFacebookCustomersPagination"
                variant="light"
                :class="
                  isNextButtonDisabled
                    ? 'mr-1 circular-button-disable'
                    : 'mr-1 circular-button'
                "
                @click="sendPrevValue"
                :disabled="isNextButtonDisabled"
                pill
              >
                <b-icon icon="chevron-compact-left" />
              </button>
              <button
                v-if="isFacebookCustomersPagination"
                variant="light"
                :class="
                  isPrevButtonDisabled
                    ? 'circular-button-disable'
                    : 'circular-button'
                "
                pill
                :disabled="isPrevButtonDisabled"
                @click="sendNextValue"
              >
                <b-icon icon="chevron-compact-right" />
              </button>
            </div> -->
          </b-col>
        </b-row>
        <div v-if="isFetchingFacebookAccountData == false">
          <div
            v-if="this.facebookCustomers.length === 0"
            style="display: flex;; justify-content:center;"
            class="m-2"
          >
            <p>No data found.</p>
          </div>
        </div>
        <div
          v-if="isFetchingFacebookAccountData"
          style="display: flex;justify-content:center;"
          class="m-5"
        >
          <b-spinner lg type="grow"></b-spinner>
        </div>
        <b-list-group>
          <b-list-group-item
            v-for="(item, index) in facebookAccountsForSelections"
            :key="item.id"
            class="pt-1 pb-1"
          >
            <div
              class="d-flex align-items-center justify-content-between ml-1 d-inline"
              style="margin-top: 8px;"
            >
              <div>
                <h6 variant="primary">{{ item.name }}</h6>
              </div>
              <div class="radio-container">
                <!-- <input
                  type="radio"
                  v-model="facebookSelected"
                  name="some-radios"
                  :value="item.name"
                /> -->
                <b-form-checkbox  v-model="facebookSelected"  :value="item"  :id="item.id" ></b-form-checkbox>
              </div>
            </div>
          </b-list-group-item>
          <div style="justify-content: center; margin-top: 10px; height: 1;">
            <b-pagination
              v-model="currentFacebookPage"
              v-if="this.facebookCustomers.length > 0"
              :total-rows="totalRows"
              :per-page="perFacebookPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            ></b-pagination>
          </div>
          <b-row style="margin-top: 5px; margin-left: 15px;">
            <b-col cols="12">
              <div v-if="isFacebookCustomersEmpty"></div>
            </b-col>
          </b-row>
        </b-list-group>

        <b-row>         
          <b-col cols="12">
            <div  style="display:flex; justify-content:space-between; gap:10px;  padding: 0px 8px !important;">
              <b-button
                variant="outline-primary"
                class="mr-1"
                @click="closeModal"
                >Cancel</b-button
              >
              <b-button
                variant="primary"
                @click="submitData"
                :disabled="isBtnDisabled"
                >Submit</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
      <!--   Amazon Profile selector modal. -->
      <div v-if="modalType == 'amazon'">
        <input
          placeholder="search amazon profile."
          class="search-option"
          v-model="amazonSearchAccount"
        />
        <div
          v-if="isAmazonProfileLoading == true"
          style="display: flex;; justify-content:center;"
          class="m-2"
        >
          <b-spinner lg type="grow"></b-spinner>
        </div>
        <div
          v-else-if="this.amazonProfileData.length === 0"
          style="display: flex;; justify-content:center;"
          class="m-2"
        >
          <p>No data found.</p>
        </div>
        <div v-else>
          <b-list-group-item
            v-for="(item, index) in paginatedItems"
            :key="item.id"
            class="pt-1 pb-1"
          >
            <div
              class="d-flex align-items-center justify-content-between ml-1 d-inline"
              style="margin-top: 8px;"
            >
              <div>
                <h6 variant="primary">{{ item.accountInfo.name }}</h6>
              </div>

              <div class="radio-container">
                <input
                  type="radio"
                  v-model="selectAmazon"
                  name="some-radios"
                  :value="item.profileId"
                />
              </div>
            </div>
          </b-list-group-item>
        </div>
        <b-pagination
          v-model="currentPage"
          v-if="this.amazonProfileData.length > 0 "
          :total-rows="totalAmazonRows"
          :per-page="perPage"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        ></b-pagination>
        <b-row >
          <b-col cols="12">
            <div  style="display:flex; justify-content:space-between; gap:10px;  padding: 0px 8px !important;">
              <b-button
                variant="outline-primary"
                class="mr-1"
                @click="closeModal"
                >Cancel</b-button
              >
              <b-button
                variant="primary"
                @click="submitAmazonProfileData"
                :disabled="isAmazonProfileBtnDisabled"
                >Submit</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BModal,
  BButton,
  BCollapse,
  BCol,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BFormRadio,
  BFormGroup,
  BIcon,
  BPagination,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "FacebookAdsSelector",
  data() {
    return {
      totalRowsPagination:0,
      currentDecreasePage: 1,
      perFacebookPage: 10,
      visible1st: true,
      internalModalVisible: this.facebookAdsModal,
      searchAccount: "",
      loadingItemId: null,
      selected: [],
      accountNames: [],
      accountIDs: [],
      loctions: [],
      selectedId: "",
      selectedAccountFacebook: null,
      selectedAmazonProfile: null,
      accountSelected: null,
      selectedRow: null,
      amazonSearchAccount: "",
      currentPage: 1,
      currentFacebookPage: 1,
      perPage: 5,
      selectAmazon: this.amazonProfileSelected,
      // amazonProfileSelected: null,
    };
  },
  props: {
    facebookAdsModal: {
      type: Boolean,
      default: true,
    },
    isFetchingFacebookAccountData: {
      type: Boolean,
    },
    SendingData: {
      type: Boolean,
      default: true,
    },
    facebookCustomers: {
      type: Array,
      default: [],
    },
    facebookCustomersTokens: {
      type: Object,
      default: null,
    },
    clientID: {
      type: String,
    },
    gmbName: {
      type: Array,
    },
    gmbSelected: {
      type: Array,
    },
    facebookSelected: {
      type: Array,
    },
    amazonProfileSelected: {
      type: String,
    },
    modalType: {
      type: String,
    },
    amazonProfileData: {
      type: Array,
    },
    isAmazonProfileLoading: {
      type: Boolean,
    },
  },
  watch: {
    facebookAdsModal(value) {
      this.internalModalVisible = value;
    },
    amazonProfileSelected(value) {
      this.selectAmazon = value;
    },
  },
  mounted() {
    this.internalModalVisible = this.facebookAdsModal;
    this.selectAmazon = this.amazonProfileSelected;
  },
  components: {
    BRow,
    BModal,
    BButton,
    BCollapse,
    BCol,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BIcon,
    BPagination,
  },
  computed: {
    filteredAccounts() {
      this.currentFacebookPage = 1
      return this.searchAccount
        ? this.facebookCustomers.filter(account =>
            account.name.toLowerCase().includes(this.searchAccount.toLowerCase()))
        : this.facebookCustomers;
    },
    facebookAccountsForSelections() {
      const start = (this.currentFacebookPage - 1) * this.perFacebookPage;
      const end = start + this.perFacebookPage;
      return this.filteredAccounts.slice(start, end);
    },
    totalRows() {
      return this.filteredAccounts.length;
    },
    totalFacebookRows() {
      return this.facebookCustomers.length;
    },
    isFacebookCustomersEmpty() {
      return this.facebookCustomers.length === 0;
    },
    isFacebookCustomersPagination() {
      return this.facebookCustomers.length > 1;
    },
    isBtnDisabled() {
      return this.facebookSelected == null;
    },
    isAmazonProfileBtnDisabled() {
      return this.selectAmazon == null;
    },
    totalAmazonRows(){
      return this.filteredAmazonProfile.length
    },
    filteredAmazonProfile() {
      this.currentPage = 1
      return this.amazonSearchAccount
        ? this.amazonProfileData.filter(account =>
            account.accountInfo.  name.toLowerCase().includes(this.amazonSearchAccount.toLowerCase()))
        : this.amazonProfileData;
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredAmazonProfile.slice(start, end);
    },
  },
  methods: {
    sendPrevValue() {
      this.pagingPrevToken = this.facebookCustomersTokens.cursors.before;
      this.$emit("token-prev-value", this.pagingPrevToken);
    },
    sendNextValue() {
      this.pagingNextToken = this.facebookCustomersTokens.cursors.after;
      this.$emit("token-next-value", this.pagingNextToken);
    },
    closeModal() {
      this.internalModalVisible = false;
    },
    search() {
      const query = this.searchAccount.toLowerCase();
      return this.facebookCustomers.filter((item) =>
        item.name.toLowerCase().includes(query)
      );
    },
    amazonProfileSearch() {
      const query = this.amazonSearchAccount.toLowerCase();
      return this.paginatedItems.filter((item) =>
        item.accountInfo.name.toLowerCase().includes(query)
      );
    },
    submitData(FacebookAccountData) {
      // this.selectedAccountFacebook = this.filteredaccounts.find(
      //   (option) => option.name === this.facebookSelected
      // );
     const ids = [];
     const names = [];
      this.facebookSelected.forEach(item => {
          if (typeof item === "object") {
              ids.push(item.id);
              names.push(item.name);
          }
      });
      FacebookAccountData = {
        adAccountIDs: ids,
        adAccountNames: names,
      };
      this.$emit("saveFacebookccountID", FacebookAccountData);
    },
    submitAmazonProfileData(amazonProfileData) {
      this.selectedAmazonProfile = this.filteredAmazonProfile.find(
        (option) => option.profileId === this.selectAmazon
      );
      amazonProfileData = {
        profileIDs: this.selectedAmazonProfile.profileId.toString(),
        profileNames: this.selectedAmazonProfile.accountInfo.name,
      };
      this.$emit("saveAmazonProfileID", amazonProfileData);
    },
    toggleFacebookModal() {
      this.internalModalVisible = false;
      this.$emit("update-facebook-modal", this.internalModalVisible);
      setTimeout(() => {
        this.searchAccount = "";
      }, 2000);
    },
  },
};
</script>

<style>
.card.strike-through .collapse-title {
  text-decoration: line-through;
}
.collapse-icon {
  color: grey;
  float: right;
  cursor: pointer;
}
.search-option {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 10px;
  width: 100%;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
.radio-container > input[type="radio"] {
  width: 25px !important;
  height: 25px !important;
  color: #a39999;
  transform: scale(0.6) !important;
  cursor: pointer;
  margin: 0;
  padding: 0;
  margin-left: 5px;
}
.circular-button {
  border-radius: 50%;
  border: none !important;
  background-color: #ede9ff !important;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999999 !important;
  font-weight: bold;
}
.circular-button-disable {
  border-radius: 50%;
  border: none !important;
  background-color: rgb(241, 238, 238) FF !important;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa9a9 !important;
}
.circular-button:hover {
  border-radius: 50%;
  border: none !important;
  background-color: #714dff !important;
  color: aliceblue !important;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
