<template>
  <div>
    <b-modal
      @hidden="toggleAccountSelectorModal"
      @ok="toggleAccountSelectorModal"
      v-model="internalModalVisible"
      ok-only
      no-close-on-backdrop
      ok-title="CLOSE"
      centered
      size="lg"
      title="Select Account"
    >
      <b-card-text>
        <app-collapse type="border" class="mainContainer">
          <app-collapse-item
            v-for="(account, accountIndex) in accounts"
            :key="account.id"
            :title="account.name"
          >
            <app-collapse type="border" class="mb-1">
              <app-collapse-item
              class="innerContainer"
                v-for="(property, propertyIndex) in account.webProperties"
                :key="property.id"
                :title="property.name"
              >
                <b-card
                  v-for="(profile, profileIndex) in property.profiles"
                  :key="profile.id"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <h5>{{ profile.name }}</h5>
                      <h6>{{ profile.id }}</h6>
                    </div>
                    <div>
                      <b-button
                        @click="
                          selectGoogleViewAccount(profile.id, profile.name, {
                            accountIndex,
                            propertyIndex,
                            profileIndex,
                          })
                        "
                        variant="primary"
                        ><b-spinner v-if="profile.loading" small /> Select
                        Account</b-button
                      >
                    </div>
                  </div>
                </b-card>
              </app-collapse-item>
            </app-collapse>
          </app-collapse-item>
        </app-collapse>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText, BCard, BButton, BSpinner } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
  props: {
    accountSelectorModal: {
      type: Boolean,
      default: false,
    },
    accounts: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      internalModalVisible : this.accountSelectorModal
    };
  },
  watch: {
    accountSelectorModal(value) {
      this.internalModalVisible = value;
    },
  },
  components: {
    BModal,
    AppCollapse,
    AppCollapseItem,
    BCardText,
    BCard,
    BButton,
    BSpinner,
  },
  methods: {
    toggleAccountSelectorModal() {
      this.$emit("toggleAccountSelectorModal", false);
    },
    selectGoogleViewAccount(viewID, profileName, buttonData) {
      this.$emit("selectGoogleViewAccount", {
        viewID,
        profileName,
        buttonData,
      });
    },
  },
};
</script>

<style scoped>
.mainContainer{
  width: 760px;
}
.innerContainer{
  width: 730px;
}
@media (max-width: 587px) {
  .mainContainer{
    width: 300px !important;
  }
  .innerContainer{
    width: 270px !important;
}
}
@media (max-width: 890px) {
  .mainContainer{
    width: 465px;
  }
  .innerContainer{
    width: 438px;
}
  
}
</style>