<template>
    <div>
      <b-modal
        no-close-on-backdrop
        scrollable
        v-model="show"
        id="modal-scrollable"
        hide-footer
        hide-header
        centered
      >
        <b-card-text>
          <div class="header text-right">
            <b-button @click="closePopUp" variant="flat-primary"
              ><feather-icon icon="XIcon"></feather-icon
            ></b-button>
          </div>
          <div class="pl-2 pr-2 pb-2 pt-0 edit-form">
            <h3 class="app-title md">Invite People to D2Scale</h3>
  
            <b-form-group
              label="Invite People"
              label-for="invite-people"
              class="mt-2"
            >
            <div class="form-container">
              <input
                placeholder="Add Email ID"
                v-model="newAccess"
                label="email"
                @input="checkEmailExists"
                :class="{ 'full-width': true,  'maximum-width': true}"
                no-data-text=""
              />
              </div>
              <p v-if="userAlreadyExistError" class="error-message">
                    {{ userAlreadyExistError }}
              </p>
            </b-form-group>
  
            <div class="d-flex justify-content-end mt-2">

              <div class="text-right">
                <b-button variant="light" @click="closePopUp" class="mr-1"
                  >Cancel</b-button
                >
                <b-button
                  :disabled="!isValidEmail"
                  @click="inviteSend"
                  variant="primary"
                >
                  Invite</b-button
                >
              </div>
            </div>
          </div>
        </b-card-text>
      </b-modal>
    </div>
  </template>
  <script>
  import {
    BModal,
    BCardText,
    BButton,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BSpinner,
    BAvatar,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import axios from "axios";
  import { getUserToken } from "@/auth/utils";
  
  export default {
    props: {
      client: {
        type: Object,
        default: () => {
          return {
            clientID: "",
            name: "",
            sector: null,
            accessList: [],
            
          };
        },
      },
      show: {
        type: Boolean,
        required: true,
        default: false,
      },
      isProcessingAccess: {
        type: Boolean,
        required: true,
        default: false,
      },
      isProcessingRevokeAccess: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    data() {
      return {
        newAccess: [],
        users: [],
        isValidEmail: false,
        showAddButton: false,
        correctEmail:"",
        userAlreadyExistError:""
      };
    },
    components: {
      BModal,
      BCardText,
      BButton,
      BFormInput,
      BInputGroup,
      BFormGroup,
      BSpinner,
      BAvatar,
      ToastificationContent,
      vSelect,
    },
    methods: {
    checkEmailExists() {
      // Clear the previous debouncer if it exists
      this.userAlreadyExistError = "";
      this.isValidEmail = false
      clearTimeout(this.debouncer);

      // Set up a new debouncer
      this.debouncer = setTimeout(() => {
        // Execute your desired function here
        this.checkEmail(this.newAccess);
      }, 500); // Adjust the timeout duration (in milliseconds) as per your needs
    },
    fetchUsers() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/get-all`,
          {},
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.users = response.data.admins;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkEmail(searchValue) {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/; // Regex pattern for email validation
        this.showAddButton = true;
        this.correctEmail = searchValue;
        for (const email of this.users) {
            if (searchValue.toLowerCase().trim() && (email.email.toLowerCase().trim() == searchValue.toLowerCase().trim())) {
                this.userAlreadyExistError = "This user already exists";
                return;
            }
        }

        this.isValidEmail = emailRegex.test(searchValue.toLowerCase());
    },
      inviteSend(searchValue) {
        const username = this.correctEmail;
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/invite/create-invite/`,
            {
              username
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
                "Content-type": "application/json",
              },
            }
          )
          .then((response) => {
            this.showToast(
              "Success",
              "Invitation sent successfully!.",
              "success"
            );
            this.$emit("close");
            this.showAddButton = false;
            this.closePopUp()
          })
          .catch((error) => {
            console.log(error.response.data);
            this.showToast("Error", "Unknown error occured.", "danger");
          });
      },
      showToast(title, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
            text,
            variant,
          },
        });
      },
      closePopUp() {
        this.newAccess = "";
        this.$emit("closePopUp", true);
      },
      sendInvite() {
        let newAccess = this.newAccess;
        this.$emit("send-invite", {
          accessList: newAccess,
          clientID: this.client.clientID,
        });
        this.newAccess = [];
      },
    },
    computed: {},
    mounted() {
        this.fetchUsers();
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  .form-container {
    display: flex;
    align-items: center;
  }
  .maximum-width{
    width: 340px;
  }
  .full-width {
    flex-grow: 1;
  }
  .button-text {
    display: inline-block;
    vertical-align: middle;
  }
  input {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  height: 45px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
.error-message {
  color: rgb(230, 28, 28);
  font-size: 12px;
}
  </style>