<template>
  <div>
    <b-modal
      @hidden="toggleGMBAccountModal"
      @ok="toggleGMBAccountModal"
      v-model="internalModalVisible"
      no-close-on-backdrop
      hide-footer
      centered
      size="lg"
      title="Select Location Group(s) Linked To Google-Ads"
      id="Modal"
    >
      <input
        placeholder="search account.."
        class="search-option"
        v-model="searchAccount"
      />     
        <b-list-group>
          <b-list-group-item
            v-for="gmbAccount in filteredccounts"
            class="pt-1 pb-1"
            :key="itemToString(gmbAccount)"
          >
         <div
              class="d-flex align-items-center justify-content-between p-0 m-0 ml-1"
            >
              <h6 variant="primary">{{ gmbAccount.accountName }}</h6>
              <div>
                <b-form-checkbox :key="itemToString(gmbAccount)" v-model="gmbSelected"  :value="gmbAccount"  :id="gmbAccount.accountID" ></b-form-checkbox>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
 
      <b-row class="mt-1">
        <b-col cols="12">
          <div  style="display:flex; justify-content:space-between; gap:10px;  padding: 0px 8px !important;">
            <b-button variant="outline-primary" class="mr-1"   @click="closeModal">Cancel</b-button> <b-button variant="primary"  @click="submitData" :disabled="this.gmbSelected.length === 0">Submit</b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BModal,
  BButton,
  BCollapse,
  BCol,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BFormCheckbox
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  name: "GMBAdsSelector",
  data() {
    return {
      visible1st: true,
      internalModalVisible: this.gmbAccountModal,
      searchAccount: "",
      loadingItemId: null,
      selected:[],
      accountNames:[],
      accountIDs:[],
      loctions:[]
    };
  },
  props: {
    gmbAccountModal: {
      type: Boolean,
      default: true,
    },
    SendingData: {
      type: Boolean,
      default: true,
    },
    gmbCustomers: {
      type: Array,
      default: [],
    },
    clientID: {
      type: String,
    },
     gmbName: {
      type: Array,
    },
    gmbSelected: {
      type: Array,
    },
    
  },
  watch: {
    gmbAccountModal(value) {
      this.internalModalVisible = value;
    },
  },
  mounted() {
    this.internalModalVisible = this.gmbAccountModal;
  },
  components: {
    BRow,
    BModal,
    BButton,
    BCollapse,
    BCol,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BFormCheckbox
  },
  computed: {
    filteredccounts() {

      if (!this.searchAccount) {
        return this.gmbCustomers;
      }

      return this.search();
    },
  },
  methods: {
    closeModal(){
      this.gmbAccountModal = false
    },
    search() {
      const query = this.searchAccount.toLowerCase();
      return this.gmbCustomers.filter((item) =>
        item.accountName.toLowerCase().includes(query)
      );
    },
    submitData(AccountData){
      this.accountIDs = this.gmbSelected.map((item)=>{
        return item.name.substring(9)
      })
      this.accountNames = this.gmbSelected.map((item)=>{
        return item.accountName
      })
      AccountData = { accountIDs: this.accountIDs,
        accountNames:this.accountNames
      }
      this.$emit("saveGMBAccountID", AccountData);
    },
    toggleGMBAccountModal() {
      this.internalModalVisible = false;
      this.$emit("update-modal", this.internalModalVisible);
      setTimeout(()=>{
        this.searchAccount = "";
      },2000)
    },
    itemToString(gmbAccount) {
      return JSON.stringify(gmbAccount); 
    },
  },
};
</script>

<style>
.card.strike-through .collapse-title {
  text-decoration: line-through;
}
.collapse-icon {
  color: grey;
  float: right;
  cursor: pointer;
}
.search-option {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 10px;
  width: 100%;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
</style>
